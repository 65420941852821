import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const AboutPage = () => (
  <Layout>
    <SEO
      title="About"
      keywords={[`bai chan kheo`, `about`, `developer`, `software`, `react`]}
    />
    <h1>About.</h1>
    <p>About goes here.</p>
  </Layout>
);

export default AboutPage;
